@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@500&display=swap");

body {
  background-color: #000210;
  font-family: "Archivo", sans-serif;
  color: #fff;
  /* background-image: linear-gradient(to right, #1d1d1d 1px, transparent 1px), linear-gradient(to bottom, #1d1d1d 1px, transparent 1px); */
  /* background-image: url("./images/bg-image.jpg");
    background-size: 100vw 100vh;
    background-repeat: no-repeat; */
}

img {
  width: 100%;
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-track {
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg,
      #1b9ffe 0%,
      rgb(115, 90, 255) 100%) !important;
}

/* Typography css begin */
h5 {
  font-size: 1rem;
}

a {
  color: inherit !important;
  text-decoration: none;
}

a:hover {
  color: #fff;
}

/* Typography css end */

/* Font size css begin */
.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

/* Font size css end */

.navbar-brand img {
  width: 150px;
  height: 60px;
}

.navbar-navlink-icon {
  margin-top: -4px;
  margin-right: 6px;
  color: #1b9ffe;
  font-size: 20px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

:root {
  --swiper-theme-color: #ffffff !important;
}

.swiper-pagination-bullet {
  background: var(--swiper-pagination-bullet-inactive-color,
      rgb(255, 255, 255)) !important;
}

.bonben-animation {
  animation: bonben 8s linear 1s infinite reverse !important;
}

.bonben-animation-2 {
  animation: bonben 16s linear 1s infinite reverse !important;
}

@keyframes bonben {
  0% {
    transform: translate(0%, 0%);
  }

  10% {
    transform: translate(1%, -1%);
  }

  20% {
    transform: translate(2%, 0%);
  }

  30% {
    transform: translate(1%, 1%);
  }

  40% {
    transform: translate(0%, 0%);
  }

  50% {
    transform: translate(-1%, -1%);
  }

  60% {
    transform: translate(-2%, 0%);
  }

  70% {
    transform: translate(-1%, -1%);
  }

  80% {
    transform: translate(0%, 0%);
  }

  90% {
    transform: translate(1%, 1%);
  }
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-family: swiper-icons;
  font-size: 14px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  background: linear-gradient(90deg,
      #1b9ffe 0%,
      rgb(115, 90, 255) 100%) !important;
  padding: 14px 16px;
  border-radius: 50%;
  padding-top: 12px;
}

.swiper-button-next,
.swiper-button-prev {
  top: 30% !important;
}

/* .swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    opacity: 0 !important;
} */

.card {
  --bs-card-bg: #060621;
}

.team-card {
  padding: 4vh 2vh;
  position: relative;
  background: #0606216b;
  /* border-radius: 0px !important; */
}

/* .team-card::before {
    align-items: center;
    content: "";
    position: absolute;
    right: -2px;
    left: -2px;
    top: -2px;
    bottom: -2px;
    background: #FFF;
    z-index: -1;
    transform: skew(1deg, 1deg);
    background: linear-gradient(90deg, #1b9ffe 0%, rgb(115, 90, 255) 100%) !important;

} */

/* .seventh-section-banner .card:hover {
    background-image: url("../src/images/inner/cardhover.png");
    background-size: 150% 150%;
    background-position: center;
} */
.progress {
  --bs-progress-height: 0.6rem;
}

.progress-bar {
  background-color: #f4b844;
}

.progress {
  --bs-progress-bg: #0c1842;
}

.modal-header .btn-close {
  box-shadow: none !important;
}

.modal-content {
  background: rgba(6, 6, 33, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.027);
}

.btn-close {
  background: url("../src/images/cross.white.svg");
  width: 0.4em !important;
  height: 0.4em !important;
}

.modal-header {
  border-bottom: 1px solid #ececec67;
}

.modal-footer {
  border-top: 1px solid #ececec67;
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
  /* remove the gap so it doesn't close */
}

.banner-top-fixed-buggy-rool-image {
  width: 300px;
  height: 100px;
  position: fixed;
  top: calc(100vh - 100px);
  right: 0px;
  z-index: 999;
  animation: example 47s infinite;
}

.banner-top-fixed-buggy-rool-image:hover {
  animation-play-state: paused;
}

@keyframes example {
  0% {
    right: -20%;
  }

  100% {
    right: 110%;
  }
}

#Path_48 {
  opacity: 0.7;
}

tspan {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

#Path_57 {
  animation: pulse1 300ms infinite alternate;
}

#Path_58-2 {
  animation: pulse2 300ms infinite alternate;
}

@keyframes pulse1 {
  0% {
    fill: #1b9ffe;
  }

  100% {
    fill: rgb(83, 83, 83);
  }
}

@keyframes pulse2 {
  0% {
    fill: #1b9ffe;
  }

  100% {
    fill: rgb(83, 83, 83);
  }
}

ol,
ul {
  padding-left: 1.5rem;
}

.bonben-animation-coin {
  animation: bonben34 7s linear 1s infinite reverse !important;
}

.bonben-animation-coin-2 {
  animation: bonben34 16s linear 1s infinite reverse !important;
}

@keyframes bonben34 {
  0% {
    transform: translate(0%, 0%);
  }

  10% {
    transform: translate(4%, -4%);
  }

  20% {
    transform: translate(8%, 0%);
  }

  30% {
    transform: translate(1%, 1%);
  }

  40% {
    transform: translate(0%, 0%);
  }

  50% {
    transform: translate(-4%, -4%);
  }

  60% {
    transform: translate(-8%, 0%);
  }

  70% {
    transform: translate(-4%, -4%);
  }

  80% {
    transform: translate(0%, 0%);
  }

  90% {
    transform: translate(4%, 4%);
  }
}

#phase1hardtarget2 {
  display: none;
}

.sales-section-conf-bg-1 {
  background-image: url("./images/ani-conf.gif");
  background-size: 100% 280%;
  background-repeat: no-repeat;
  background-position: center;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
  .made-by-decosystem-banner-section .card {
    padding: 20px 10px;
    height: 100%;
    background: rgba(27, 87, 254, 0.055);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    border: 1px solid #00e5ff11;
    border-radius: 12px;
  }

  .sales-text-7 {
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #1b9ffe;
  }

  .sales-soft-section {
    position: relative;
    top: -20px;
  }

  .sales-line-1 {
    width: 2px;
    height: 25px;
    /* background-color: #FFF; */
    border-radius: 50%;
    text-align: center;
    margin: auto;
    opacity: 0.6;
  }

  .sales-text-5 {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.1em;
  }

  .sales-text-6 {
    font-size: 11px;
  }

  .progress-section-1 {
    padding: 3px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.082);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }

  .progress-color-1 {
    background: linear-gradient(90deg,
        #1b9ffe 0%,
        rgb(115, 90, 255) 100%) !important;
  }

  .sales-text-4 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
  }

  .sales-buy-button {
    padding: 10px 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 25px;
    border: none;
    background: linear-gradient(90deg,
        #1b9ffe 0%,
        rgb(115, 90, 255) 100%) !important;
    color: #fff !important;
    overflow: hidden;
    font-size: 14px !important;
    font-weight: 300 !important;
    transition: all 0.3s ease-in-out;
    outline: none !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 100%;
    margin-top: 40px;
  }

  .sales-text-3 {
    font-size: 44px;
    margin-bottom: 0px;
    position: relative;
    top: 2px;
    opacity: 0.4;
  }

  .sales-text-1 {
    font-size: 34px;
    margin-bottom: 0px;
  }

  .sales-text-2 {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .sales-section-1 {
    background-color: rgba(182, 184, 199, 0.048);
    -webkit-backdrop-filter: blur(18px);
    backdrop-filter: blur(18px);
    padding: 20px 30px;
    border-radius: 6px;
  }

  .sale {
    background: linear-gradient(90deg, #1b9ffea9 0%, rgba(115, 90, 255, 0.63));
    padding: 1px;
    position: relative;
    border-radius: 4px;
  }

  .sale-body {
    padding: 60px 60px;
    background: #121637;
    background-image: url("../src/images/inner/swiper-bg.png");
    background-size: 100% 100%;
    border-radius: 4px;
  }

  .sale-body-2 {
    padding: 30px 30px;
    background: #121637;
    background-image: url("../src/images/inner/swiper-bg.png");
    background-size: 100% 100%;
    border-radius: 4px;
  }

  .sales-tabs-text-1 {
    font-size: 12px;
  }

  .howbuy-content-section {
    background: rgba(14, 20, 52, 0.25);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .two-tabs-section-banner .nav-item {
    width: 32%;
    margin-left: 1.3%;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    background: #060621;
    background-image: url("./images/banner/bg-card.png");
    border-radius: 0.375rem;
  }

  .two-tabs-section-banner .nav-pills .nav-link.active,
  .two-tabs-section-banner .nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: #1b9ffe;
  }

  .two-tabs-section-banner .nav-pills .nav-link {
    width: 100%;
    color: #fff;
    padding: 18px 10px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .leadership-table-section .table> :not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 0px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  }

  .leadership-table-section table {
    color: #fff;
  }

  .leadership-table-section table thead {
    background-color: #1b9ffe;
    border: none;
  }

  .banner-top-text-1 {
    font-size: 3.4vw;
    font-weight: 700 !important;
    line-height: 1 !important;
  }

  .idolaunchbutton-banner {
    position: relative;
    left: -80px;
    /* bottom: -70px; */
    width: 70%;
  }

  .idolaunchtext-banner {
    position: relative;
    top: -70px;
  }

  .banner-coin-fixed-images-1 {
    position: absolute;
    top: 64vh;
    left: 48vw;
    width: 3%;
    height: 7%;
  }

  .banner-coin-fixed-images-2 {
    position: absolute;
    top: 40vh;
    left: 64vw;
    width: 3%;
    height: 6%;
  }

  .banner-coin-fixed-images-3 {
    position: absolute;
    top: 30vh;
    left: 78vw;
    width: 4%;
    height: 8%;
  }

  .banner-coin-fixed-images-4 {
    position: absolute;
    top: 64vh;
    left: 78vw;
    width: 5%;
    height: 10%;
  }

  .vesting-7 {
    border-left: 7px solid #e91e63 !important;
  }

  .vesting-6 {
    border-left: 7px solid #2196f3 !important;
  }

  .vesting-5 {
    border-left: 7px solid #3f51b5 !important;
  }

  .vesting-4 {
    border-left: 7px solid #03a9f4 !important;
  }

  .vesting-3 {
    border-left: 7px solid #9c27b0 !important;
  }

  .vesting-2 {
    border-left: 7px solid #673ab7 !important;
  }

  .vesting-1 {
    border-left: 7px solid #00bcd4 !important;
  }

  .vesting-section-card {
    background: rgba(0, 2, 16, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.009);
    padding-top: 20px;
    padding-bottom: 20px;
    /* border-left: 7px solid #1b9ffe; */
    padding-left: 20px;
    border-radius: 4px;
  }

  .vest-icon-1 {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgba(27, 159, 254, 0.1);
    border: 1px solid #cecece13;
    border-radius: 8px;
    font-size: 6rem;
  }

  .vest-text-3 {
    font-size: 11px;
  }

  .vest-text-2 {
    font-size: 13px;
    margin-bottom: 0px;
    font-weight: 500;
  }

  .vest-text-1 {
    text-transform: capitalize;
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 700;
  }

  .vesting-table-section {
    background: rgba(0, 2, 16, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.062);
    padding: 20px;
  }

  .blog-text-1 {
    font-size: 10px;
    text-transform: uppercase;
  }

  .blog-text-2 {
    font-size: 10px;
    color: #1b9ffe;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .banner-section-7 .card-body {
    padding: 30px 30px;
  }

  .banner-section-7 .card {
    border: 1px solid #cecece13;
    border-radius: 20px;
    background: rgba(32, 88, 128, 0.1);
    cursor: pointer;
    transition: 0.2s;
    /* Animation */
  }

  .banner-section-7 .card-body {
    transition: 0.2s;
    /* Animation */
  }

  .banner-section-7 .card:hover {
    transform: scale(1.01);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .banner-content {
    position: absolute;
    top: 4%;
    left: 80%;
    display: none;
    font-size: 40px;
  }

  .banner-section-7 .card:hover .banner-content {
    display: block;
  }

  .banner-section-7 .card:hover {
    background-color: #1b9ffe15;
  }

  .banner-section-7 .card:hover .card-body {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .banner-section-7 .card img {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    width: 100%;
    height: 180px;
  }

  .new-banner-swiper .swiper-button-prev,
  .new-banner-swiper .swiper-rtl .swiper-button-next {
    left: auto;
    right: 200px;
  }

  .new-banner-swiper .swiper-button-next,
  .new-banner-swiper .swiper-rtl .swiper-button-prev {
    left: auto;
    right: 120px;
  }

  .new-banner-swiper .swiper-button-next:after,
  .new-banner-swiper .swiper-button-prev:after {
    font-size: 16px;
  }

  .new-banner-swiper .swiper-button-next,
  .new-banner-swiper .swiper-button-prev {
    position: absolute;
    top: 90% !important;
    z-index: 10;
    width: 2.635rem;
    height: 2.625rem;
    border-style: none;
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
    border-radius: 10px;
    color: #fff;
    border-radius: 50%;
  }

  .new-banner-swiper .swiper {
    padding-top: 0px;
    padding-bottom: 100px;
  }

  .banner-section-7 .swiper-button-prev,
  .banner-section-7 .swiper-rtl .swiper-button-next {
    left: auto;
    right: 200px;
  }

  .banner-section-7 .swiper-button-next,
  .banner-section-7 .swiper-rtl .swiper-button-prev {
    left: auto;
    right: 120px;
  }

  .banner-section-7 .swiper-button-next:after,
  .banner-section-7 .swiper-button-prev:after {
    font-size: 16px;
  }

  .banner-section-7 .swiper-button-next,
  .banner-section-7 .swiper-button-prev {
    position: absolute;
    top: 80% !important;
    z-index: 10;
    width: 2.635rem;
    height: 2.625rem;
    border-style: none;
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
    border-radius: 10px;
    color: #fff;
    border-radius: 50%;
  }

  .banner-section-7 .swiper {
    padding-bottom: 200px;
  }

  .banner-video-bottom-1 {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    object-fit: cover;
    z-index: -9;
    opacity: 0.4;
  }

  .banner-section-8 .input-group {
    border: 1px solid #2d2c3359;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
  }

  .banner-section-8 .input-group:hover {
    border: 1px solid #1b9ffe6c;
  }

  .banner-section-8 .input-group-text {
    margin-left: 0px !important;
    box-shadow: none !important;
    min-height: 3.75rem;
    padding: 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background: rgba(32, 88, 128, 0.1);
    border: 0px solid #2d2c33 !important;
    border-radius: 0.375rem;
  }

  .banner-section-8 ::placeholder {
    color: #ffffff !important;
    font-size: 15px;
  }

  .banner-section-8 .form-control {
    box-shadow: none !important;
    min-height: 3.75rem;
    padding: 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background: rgba(32, 88, 128, 0.1);
    border: 0px solid #2d2c33;
  }

  .banner-section-8 .form-control:focus {
    color: #fff;
    background: rgba(32, 88, 128, 0.1);
    border: 0px solid #2d2c33;
  }

  .stay-mail-text-2 {
    font-size: 2rem;
  }

  .stay-mail-text-1 {
    font-size: 10px;
    letter-spacing: 2px;
    color: #1b9ffe;
  }

  .footer-typo-icons {
    color: #ffffff !important;
    position: relative;
    left: -6px;
    opacity: 0;
    transition: 0.3s;
  }

  .footer-typo a {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 1.3rem;
    color: #b7b7bb !important;
    transition: color 0.2s;
  }

  .footer-typo a:hover .footer-typo-icons {
    left: 2px;
    opacity: 1;
  }

  .footer-typo a:hover {
    color: #ffffff !important;
  }

  .footer-text-1 {
    font-size: 1rem;
    margin-bottom: 1.25rem;
  }

  .navbar-brand-footer {
    width: 150px;
    height: 70px;
  }

  .footer-social-icons-link a {
    cursor: pointer;
    margin-left: 2rem;
    font-size: 1.3rem;
    transition: color 0.2s;
    color: #67666e !important;
  }

  .footer-social-icons-link a:hover {
    color: #ffffff !important;
  }

  .launch-icon-1 {
    font-size: 24px;
    margin-right: 20px;
  }

  .launch-text-3 {
    font-size: 12px;
    font-weight: 400;
    color: #ffffffd3;
    letter-spacing: 0.5px;
  }

  .launch-text-2 {
    font-size: 1.1rem;
  }

  .why-d-launchpad-section {
    background-image: linear-gradient(180deg, #06062163 50%, transparent 10%);
  }

  .launch-text-1 {
    font-size: 0.9rem;
    width: 60%;
    margin: auto;
  }

  .active-ido-href {
    font-size: 14px;
    color: #0d6efd !important;
    text-decoration: underline;
  }

  .steps-section-image-3 img {
    width: 130px;
    height: 100px;
  }

  .steps-section-image-2 img {
    width: 80px;
    height: 80px;
  }

  .fifth-section-banner .card {
    background-color: rgba(182, 184, 199, 0.096);
    backdrop-filter: blur(1px);
  }

  .banner-top-fixed-timer-rool-image {
    width: 26vh;
    height: 26vh;
    position: fixed;
    top: calc(100vh - 26vh);
    right: 0px;
    z-index: 999;
  }

  .our-product-dropdown {
    background-color: transparent;
    color: #fff;
    outline: none !important;
    border: none !important;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    border-right: 1px solid rgba(255, 255, 255, 0.137);
    transform: skew(-15deg);
    margin-top: 2px;
  }

  .ido-active-buy-section-modal .input-group {
    padding-bottom: 6px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.363);
  }

  .ido-active-buy-section-modal .input-group-sm>.form-control {
    padding: 0.05rem 0.5rem;
  }

  .ido-active-buy-section-modal ::placeholder {
    color: #fff !important;
  }

  .ido-active-buy-section-modal .input-group-text {
    font-size: 15px;
    font-weight: 600;
    border: none;
  }

  .ido-active-buy-section-modal .input-group-text img {
    width: 22px;
    height: 22px;
    margin-right: 4px;
    position: relative;
    top: -2px;
  }

  .navbar-connect-wallet {
    border: 0px;
    border-radius: 16px;
    box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    background-color: #1b9ffe;
    text-decoration: none !important;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 20px;
    position: relative;
    top: -4px;
  }

  .ido-enable-disable {
    pointer-events: none;
    opacity: 0.1;
  }

  .approve-connect-wallet-section {
    position: absolute;
    top: 28%;
    left: 28%;
  }

  .ido-active-buy-approve {
    background-color: transparent;
    border: 2px solid #1b9ffe;
    border-radius: 8px;
    padding: 8px 0px;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 1px;
  }

  .ido-active-buy-text-1 {
    font-size: 13px;
  }

  .ido-active-buy-section .input-group {
    padding-bottom: 6px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.363);
  }

  .ido-active-buy-section .input-group-sm>.form-control {
    padding: 0.05rem 0.5rem;
  }

  .ido-active-buy-section ::placeholder {
    color: #fff !important;
  }

  .form-control:focus {
    background-color: transparent;
  }

  .form-control {
    box-shadow: none !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff !important;
    background-color: transparent;
    border: 0px solid #ced4da;
  }

  .ido-active-buy-max {
    background-color: #f4b844;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    border: none;
    height: 22px;
    margin-top: 2px;
  }

  .ido-active-buy-section .input-group-text {
    font-size: 13px;
    font-weight: 600;
    border: none;
  }

  .ido-active-buy-section .input-group-text img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    position: relative;
    top: -2px;
  }

  .ido-active-buy-section {
    background-color: #15296f;
    padding: 22px 16px;
    border-radius: 15px;
    position: relative;
  }

  .ido-active-text-8 {
    font-size: 14px;
    font-weight: 500;
  }

  .ido-active-text-7 {
    font-size: 18px;
    font-weight: 500;
  }

  .id-active-background-bg {
    background-color: #15296f;
    padding: 12px 16px;
    border-radius: 15px;
  }

  .ido-active-text-6 {
    color: #c2c2c2;
  }

  .ido-active-text-5 {
    font-size: 18px;
  }

  .ido-level-button-1 {
    background-color: #15296f;
    border-radius: 12px;
    padding: 4px 8px;
    font-size: 14px;
  }

  .ido-active-image-3 img {
    width: 60px;
    border-radius: 50%;
  }

  .ido-copy-button {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    padding: 4px 12px 4px 8px;
    background: rgba(244, 184, 68, 0.1);
    border-radius: 15px;
    border: none;
  }

  .ido-active-text-4 {
    font-size: 16px;
  }

  .ido-active-text-3 {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .active-ido-background {
    background-color: #060621;
    padding: 20px 25px;
    border-radius: 10px;
  }

  .ido-icon-button-1 {
    position: relative;
    top: -2px;
    background-color: #15296f;
    border-radius: 50%;
    padding: 6px;
    font-size: 28px;
    margin-right: 4px;
  }

  .ido-active-text-2 {
    font-size: 18px;
    line-height: 180%;
    letter-spacing: -0.02em;
    color: #c2c2c2;
    width: 70%;
  }

  .ido-active-button-1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    padding: 6px 18px;
    color: #f4b844;
    background: rgba(244, 184, 68, 0.2);
    border: none;
    border-radius: 8px;
  }

  .ido-active-image-2 img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #373737;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    margin-left: 0;
    padding: 8px;
  }

  .ido-active-text-1 {
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .ido-active-image-1 img {
    width: 90px;
    border-radius: 50%;
  }

  .roadmap-image-2 {
    width: 60%;
    width: 60%;
    float: right;
  }

  .fade-left-img {
    width: 60px;
    height: 20px;
  }

  .input-group-text {
    box-shadow: none !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-color: transparent;
    border: 1px solid #ced4da;
    border-right: 0px;
  }

  .hero-section-ido-launchpad-tabs-banner .form-control {
    box-shadow: none !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-color: transparent;
    border: 1px solid #ced4da;
    border-left: 0px;
  }

  .hero-section-ido-launchpad-tabs-banner .nav-pills .nav-link.active,
  .hero-section-ido-launchpad-tabs-banner .nav-pills .show>.nav-link {
    background: linear-gradient(90deg,
        #1b9ffe 0%,
        rgb(115, 90, 255) 100%) !important;
  }

  .hero-section-ido-launchpad-tabs-banner .nav-link {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    -webkit-transform: skew(-15deg);
    transform: skew(-15deg);
  }

  .hero-section-ido-launchpad-tabs-banner .nav-link:hover {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .ido-launchpad-button {
    padding: 10px 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 12px;
    border: none;
    background: linear-gradient(90deg,
        #1b9ffe 0%,
        rgb(115, 90, 255) 100%) !important;
    color: #fff !important;
    overflow: hidden;
    font-size: 14px !important;
    font-weight: 300 !important;
    transition: all 0.3s ease-in-out;
    outline: none !important;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .application-form-ido-section {
    padding: 48px 66px;
    background: #060621;
    backdrop-filter: blur(16.5px);
    -webkit-backdrop-filter: blur(16.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.021);
  }

  .hero-section-ido-launchpad-banner .form-check-label {
    font-size: 13px;
    margin-bottom: 0.5rem;
  }

  .hero-section-ido-launchpad-banner .form-check-input {
    box-shadow: none !important;
  }

  .hero-section-ido-launchpad-banner .form-control:focus {
    background-color: rgba(255, 255, 255, 0);
    border-color: #ced4da;
  }

  .hero-section-ido-launchpad-banner .form-control {
    box-shadow: none !important;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff !important;
    background-color: rgba(255, 255, 255, 0);
    background-clip: padding-box;
    border-bottom: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .footer-section-bottom {
    background-image: url("./images/banner/footer-bg-section.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .seventh-section-banner {
    background-image: url("./images/banner/team-banner-image.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .footer-social-icons a {
    width: 100px;
    height: 60px;
  }

  .footer-link-section a {
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    margin-right: 16px;
    letter-spacing: 1px;
  }

  .banner-top-fixed-images {
    position: absolute;
    top: 0%;
    right: 0%;
    width: 20%;
    height: 40%;
    z-index: -1;
  }

  .banner-top-fixed-images-particles-1 {
    position: absolute;
    top: 20%;
    left: 50%;
    width: 300px;
    height: 200px;
    z-index: -1;
  }

  .banner-top-fixed-images-particles-2 {
    position: absolute;
    top: 80%;
    left: 60%;
    width: 250px;
    height: 150px;
    z-index: -1;
  }

  .banner-top-images {
    width: 100%;
  }

  .contact-text-2 {
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 2px;
  }

  .contact-text-1 {
    font-size: 2rem;
    font-weight: 800;
    letter-spacing: 2px;
  }

  .team-text-3 {
    font-size: 20px;
    margin-right: 8px;
  }

  .team-text-2 {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 2px;
  }

  .team-text-1 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 2px;
  }

  .team-card img {
    width: 150px;
    height: 150px;
  }

  .roadmap-image-1 img {
    width: 5vw;
    position: absolute;
    right: -18%;
    top: 100px;
    height: 20px;
    transform: rotate(90deg);
  }

  .roadmap-text-1 {
    /* background: linear-gradient(90deg, #1b9ffe 0%, rgb(164, 147, 255) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent; */
    font-size: 2rem !important;
    letter-spacing: 0.02em !important;
    letter-spacing: 0.13rem !important;
    font-weight: 700;
  }

  .eight-section-banner .card {
    background-image: url("./images/banner/bg-card.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .fifth-section-banner {
    background-image: url("./images/banner/bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .left-days-card-section {
    padding-left: 10px;
    background: linear-gradient(90deg,
        #1b9ffe3b 0%,
        rgba(115, 90, 255, 0.281) 100%) !important;
    width: 50%;
    height: 100%;
    padding: 5px 3px;
    clip-path: polygon(0% 0%, 100% 0, 96% 45%, 91% 100%, 0% 100%);
  }

  .ido-text-13 {
    margin-left: 5%;
    font-size: 24px;
    font-weight: 600;
  }

  .ido-text-12 {
    font-size: 14px;
    font-weight: 600;
  }

  .ido-text-11 {
    font-size: 13px;
    font-weight: 400;
    color: #ffffffd3;
    letter-spacing: 0.5px;
  }

  .ido-text-10 {
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  .ido-text-9 {
    font-size: 1.15rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  .star-second-image {
    margin-left: 10px;
    position: relative;
    top: -6px;
    width: 25px;
  }

  .two-section-banner {
    background-color: transparent;
    background-image: linear-gradient(180deg, transparent 50%, #06062163 10%);
  }

  .third-section-banner {
    background-color: #06062163;
  }

  .steps-section-image img {
    width: 60px;
    height: 120px;
  }

  .steps-section-image-launch img {
    width: 60px;
    height: 60px;
  }

  .ido-text-8 {
    font-size: 0.95rem;
    font-weight: 500;
  }

  .ido-text-7 {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .ido-text-6 {
    font-size: 1.7rem;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 2px;
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
  }

  .ido-text-3 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
  }

  .ido-text-4 {
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
  }

  .ido-text-44 {
    margin: auto;
    font-size: 1rem;
    width: 80%;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
  }

  .ido-text-5 {
    color: #82b8df;
    font-size: 0.95rem;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .swiper-sale-image-1 img {
    width: 100px;
    height: 100px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .swiper-sale-image-2 img {
    width: 50px;
    height: 50px;
  }

  .box {
    padding: 2vh 5vh;
    position: relative;
    /* height: 400px; */
    background: #060621;
    background-image: url("../src/images/inner/swiper-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .box::before {
    align-items: center;
    content: "";
    position: absolute;
    right: -2px;
    left: -2px;
    top: -2px;
    bottom: -2px;
    background: #fff;
    z-index: -1;
    transform: skew(1deg, 1deg);
    background: linear-gradient(90deg,
        #1b9ffe 0%,
        rgb(115, 90, 255) 100%) !important;
  }

  .get-start-1 {
    padding: 10px 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 25px;
    border: none;
    background: linear-gradient(90deg,
        #1b9ffe 0%,
        rgb(115, 90, 255) 100%) !important;
    color: #fff !important;
    overflow: hidden;
    font-size: 14px !important;
    font-weight: 300 !important;
    transition: all 0.3s ease-in-out;
    outline: none !important;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .ido-text-2 {
    font-size: 1.05rem !important;
  }

  .ido-text-1 {
    font-size: 1.3rem !important;
    color: #1b9ffe;
    font-weight: 600;
  }

  .navbar-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }

  .navbar .nav-link {
    display: inline-block;
    color: #fff;
    text-decoration: none;
    padding-left: 1vw !important;
    padding-right: 1vw !important;
  }

  .navbar .nav-link::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: #fff;
    transition: width 0.3s;
  }

  .navbar .nav-link:hover::after {
    width: 100%;
  }

  .navbar-background-color {
    padding: 1vh 3vh;
    background: #060621a6;
    /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.178); */
    backdrop-filter: blur(16.5px);
    -webkit-backdrop-filter: blur(16.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.021);
  }

  /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .navbar-background-color {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            background-color: rgba(255, 255, 255, 0.089);
        }
    } */

  .navbar .nav-link {
    color: #fff;
    font-size: 0.75vw;
    text-transform: uppercase;
    font-weight: 600;
    border-right: 1px solid rgba(255, 255, 255, 0.137);
    -webkit-transform: skew(-15deg);
    transform: skew(-15deg);
  }

  .navbar .nav-link:hover {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
  }

  /* .hero-section-banner-height {
    height: 80vh;
  } */
}

/* ============ mobile view ============ */
@media (max-width: 991px) {
  .made-by-decosystem-banner-section .card {
    padding: 20px 10px;
    height: 100%;
    background: rgba(27, 87, 254, 0.055);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 10px;
    border: 1px solid #00e5ff11;
    border-radius: 12px;
  }

  .sales-text-7 {
    font-size: 14px;
    letter-spacing: 0.01em;
    color: #1b9ffe;
  }

  .sales-soft-section {
    position: relative;
    top: -20px;
  }

  .sales-line-1 {
    width: 2px;
    height: 25px;
    /* background-color: #FFF; */
    border-radius: 50%;
    text-align: center;
    margin: auto;
    opacity: 0.6;
  }

  .sales-text-5 {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.1em;
  }

  .sales-text-6 {
    font-size: 11px;
  }

  .progress-section-1 {
    padding: 3px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.082);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }

  .progress-color-1 {
    background: linear-gradient(90deg,
        #1b9ffe 0%,
        rgb(115, 90, 255) 100%) !important;
  }

  .sales-text-4 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
  }

  .sales-buy-button {
    padding: 10px 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 25px;
    border: none;
    background: linear-gradient(90deg,
        #1b9ffe 0%,
        rgb(115, 90, 255) 100%) !important;
    color: #fff !important;
    overflow: hidden;
    font-size: 14px !important;
    font-weight: 300 !important;
    transition: all 0.3s ease-in-out;
    outline: none !important;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 100%;
    margin-top: 40px;
  }

  .sales-text-3 {
    font-size: 44px;
    margin-bottom: 0px;
    position: relative;
    top: 2px;
    opacity: 0.4;
  }

  .sales-text-1 {
    font-size: 34px;
    margin-bottom: 0px;
  }

  .sales-text-2 {
    font-size: 14px;
    margin-bottom: 0px;
  }

  .sales-section-1 {
    background-color: rgba(182, 184, 199, 0.048);
    -webkit-backdrop-filter: blur(18px);
    backdrop-filter: blur(18px);
    padding: 20px 30px;
    border-radius: 6px;
  }

  .sale {
    background: linear-gradient(90deg, #1b9ffea9 0%, rgba(115, 90, 255, 0.63));
    padding: 1px;
    position: relative;
    border-radius: 4px;
  }

  .sale-body {
    padding: 30px 30px;
    background: #121637;
    background-image: url("../src/images/inner/swiper-bg.png");
    background-size: 100% 100%;
    border-radius: 4px;
  }

  .sale-body-2 {
    padding: 15px 15px;
    background: #121637;
    background-image: url("../src/images/inner/swiper-bg.png");
    background-size: 100% 100%;
    border-radius: 4px;
  }

  .sales-tabs-text-1 {
    font-size: 12px;
  }

  .howbuy-content-section {
    background: rgba(14, 20, 52, 0.25);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .two-tabs-section-banner .nav-item {
    width: 32%;
    margin-left: 1.3%;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    background: #060621;
    background-image: url("./images/banner/bg-card.png");
    border-radius: 0.375rem;
  }

  .two-tabs-section-banner .nav-pills .nav-link.active,
  .two-tabs-section-banner .nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: #1b9ffe;
  }

  .two-tabs-section-banner .nav-pills .nav-link {
    width: 100%;
    color: #fff;
    padding: 18px 10px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .banner-top-text-1 {
    font-size: calc(1.125rem + 4.5vw);
    font-weight: 700 !important;
    line-height: 1 !important;
  }

  .idolaunchbutton-banner {
    position: relative;
    left: -30px;
    width: 60%;
    /* bottom: -70px; */
  }

  .idolaunchtext-banner {
    position: relative;
    top: -30px;
  }

  .banner-coin-fixed-images-1 {
    position: absolute;
    top: 34vh;
    left: 48vw;
    width: 9%;
    height: 5%;
  }

  .banner-coin-fixed-images-2 {
    position: absolute;
    top: 40vh;
    left: 64vw;
    width: 8%;
    height: 4%;
  }

  .banner-coin-fixed-images-3 {
    position: absolute;
    top: 30vh;
    left: 78vw;
    width: 8%;
    height: 6%;
  }

  .banner-coin-fixed-images-4 {
    position: absolute;
    top: 44vh;
    left: 78vw;
    width: 9%;
    height: 5%;
  }

  .vesting-7 {
    border-left: 7px solid #e91e63 !important;
  }

  .vesting-6 {
    border-left: 7px solid #2196f3 !important;
  }

  .vesting-5 {
    border-left: 7px solid #3f51b5 !important;
  }

  .vesting-4 {
    border-left: 7px solid #03a9f4 !important;
  }

  .vesting-3 {
    border-left: 7px solid #9c27b0 !important;
  }

  .vesting-2 {
    border-left: 7px solid #673ab7 !important;
  }

  .vesting-1 {
    border-left: 7px solid #00bcd4 !important;
  }

  .vesting-section-card {
    background: rgba(0, 2, 16, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.009);
    padding-top: 20px;
    padding-bottom: 20px;
    /* border-left: 7px solid #1b9ffe; */
    padding-left: 20px;
    border-radius: 4px;
  }

  .vest-icon-1 {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background: rgba(27, 159, 254, 0.1);
    border: 1px solid #cecece13;
    border-radius: 8px;
    font-size: 6rem;
  }

  .vest-text-3 {
    font-size: 11px;
  }

  .vest-text-2 {
    font-size: 13px;
    margin-bottom: 0px;
    font-weight: 500;
  }

  .vest-text-1 {
    text-transform: capitalize;
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 700;
  }

  .vesting-table-section {
    background: rgba(0, 2, 16, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.062);
    padding: 20px;
  }

  .blog-text-1 {
    font-size: 10px;
    text-transform: uppercase;
  }

  .blog-text-2 {
    font-size: 10px;
    color: #1b9ffe;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .footer-typo-icons {
    color: #ffffff !important;
    position: relative;
    left: -6px;
    opacity: 0;
    transition: 0.3s;
  }

  .footer-typo a {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #67666e !important;
    transition: color 0.2s;
  }

  .footer-typo a:hover .footer-typo-icons {
    left: 2px;
    opacity: 1;
  }

  .footer-typo a:hover {
    color: #ffffff !important;
  }

  .footer-text-1 {
    font-size: 1rem;
    margin-bottom: 1.05rem;
  }

  .navbar-brand-footer {
    width: 150px;
    height: 70px;
  }

  .footer-social-icons-link a {
    cursor: pointer;
    margin-left: 1.4rem;
    font-size: 1.3rem;
    transition: color 0.2s;
    color: #67666e !important;
  }

  .footer-social-icons-link a:hover {
    color: #ffffff !important;
  }

  .banner-video-bottom-1 {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    object-fit: cover;
    z-index: -9;
    opacity: 0.4;
  }

  .banner-section-7 .card-body {
    padding: 30px 30px;
  }

  .banner-section-7 .card {
    border: 1px solid #cecece13;
    border-radius: 20px;
    background: rgba(32, 88, 128, 0.1);
    cursor: pointer;
    transition: 0.2s;
    /* Animation */
  }

  .banner-section-7 .card-body {
    transition: 0.2s;
    /* Animation */
  }

  .banner-section-7 .card:hover {
    transform: scale(1.01);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .banner-content {
    position: absolute;
    top: 4%;
    left: 80%;
    display: none;
    font-size: 40px;
  }

  .banner-section-7 .card:hover .banner-content {
    display: block;
  }

  .banner-section-7 .card:hover {
    background-color: #1b9ffe15;
  }

  .banner-section-7 .card:hover .card-body {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .banner-section-7 .card img {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    width: 100%;
    height: 180px;
  }

  .new-banner-swiper .swiper-button-prev,
  .new-banner-swiper .swiper-rtl .swiper-button-next {
    left: auto;
    right: 200px;
  }

  .new-banner-swiper .swiper-button-next,
  .new-banner-swiper .swiper-rtl .swiper-button-prev {
    left: auto;
    right: 120px;
  }

  .new-banner-swiper .swiper-button-next:after,
  .new-banner-swiper .swiper-button-prev:after {
    font-size: 16px;
  }

  .new-banner-swiper .swiper-button-next,
  .new-banner-swiper .swiper-button-prev {
    position: absolute;
    top: 90% !important;
    z-index: 10;
    width: 2.635rem;
    height: 2.625rem;
    border-style: none;
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
    border-radius: 10px;
    color: #fff;
    border-radius: 50%;
  }

  .new-banner-swiper .swiper {
    padding-bottom: 100px;
  }

  .banner-section-7 .swiper-button-prev,
  .banner-section-7 .swiper-rtl .swiper-button-next {
    left: auto;
    right: 200px;
  }

  .banner-section-7 .swiper-button-next,
  .banner-section-7 .swiper-rtl .swiper-button-prev {
    left: auto;
    right: 120px;
  }

  .banner-section-7 .swiper-button-next:after,
  .banner-section-7 .swiper-button-prev:after {
    font-size: 16px;
  }

  .banner-section-7 .swiper-button-next,
  .banner-section-7 .swiper-button-prev {
    position: absolute;
    top: 80% !important;
    z-index: 10;
    width: 2.635rem;
    height: 2.625rem;
    border-style: none;
    background: rgba(27, 159, 254, 0.1);
    -webkit-backdrop-filter: blur(60px);
    backdrop-filter: blur(60px);
    border-radius: 10px;
    color: #fff;
    border-radius: 50%;
  }

  .banner-section-7 .swiper {
    padding-bottom: 200px;
  }

  .banner-section-8 .input-group {
    border: 1px solid #2d2c3359;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
  }

  .banner-section-8 .input-group:hover {
    border: 1px solid #1b9ffe6c;
  }

  .banner-section-8 .input-group-text {
    margin-left: 0px !important;
    box-shadow: none !important;
    min-height: 3.75rem;
    padding: 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background: rgba(32, 88, 128, 0.1);
    border: 0px solid #2d2c33 !important;
    border-radius: 0.375rem;
  }

  .banner-section-8 ::placeholder {
    color: #ffffff !important;
    font-size: 15px;
  }

  .banner-section-8 .form-control {
    box-shadow: none !important;
    min-height: 3.75rem;
    padding: 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background: rgba(32, 88, 128, 0.1);
    border: 0px solid #2d2c33;
  }

  .banner-section-8 .form-control:focus {
    color: #fff;
    background: rgba(32, 88, 128, 0.1);
    border: 0px solid #2d2c33;
  }

  .stay-mail-text-2 {
    font-size: 2rem;
  }

  .stay-mail-text-1 {
    font-size: 10px;
    letter-spacing: 2px;
    color: #1b9ffe;
  }

  .launch-icon-1 {
    font-size: 24px;
    margin-right: 20px;
  }

  .launch-text-3 {
    font-size: 13px;
    font-weight: 400;
    color: #ffffffd3;
    letter-spacing: 0.5px;
  }

  .launch-text-2 {
    font-size: 1.1rem;
  }

  .why-d-launchpad-section {
    background-image: linear-gradient(180deg, #06062163 50%, transparent 10%);
  }

  .launch-text-1 {
    font-size: 0.8rem;
  }

  .active-ido-href {
    font-size: 14px;
    color: #0d6efd !important;
    text-decoration: underline;
  }

  .steps-section-image-3 img {
    width: 130px;
    height: 100px;
  }

  .steps-section-image-2 img {
    width: 60px;
    height: 60px;
  }

  .fifth-section-banner .card {
    background-color: rgba(182, 184, 199, 0.096);
    backdrop-filter: blur(1px);
  }

  .banner-top-fixed-timer-rool-image {
    width: 20vh;
    height: 20vh;
    position: fixed;
    top: calc(100vh - 20vh);
    right: 0px;
    z-index: 999;
  }

  .our-product-dropdown {
    background-color: transparent;
    color: #fff;
    outline: none !important;
    border: none !important;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    border-right: 1px solid rgba(255, 255, 255, 0.137);
    transform: skew(-15deg);
    margin-top: 2px;
  }

  .navbar-connect-wallet {
    border: 0px;
    border-radius: 16px;
    box-shadow: rgb(14 14 44 / 40%) 0px -1px 0px 0px inset;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    background-color: #1b9ffe;
    text-decoration: none !important;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 10px 20px;
    position: relative;
    top: 4px;
  }

  .ido-enable-disable {
    pointer-events: none;
    opacity: 0.1;
  }

  .approve-connect-wallet-section {
    position: absolute;
    top: 28%;
    left: 28%;
  }

  .ido-active-buy-approve {
    background-color: transparent;
    border: 2px solid #1b9ffe;
    border-radius: 8px;
    padding: 8px 0px;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 1px;
  }

  .ido-active-buy-text-1 {
    font-size: 13px;
  }

  .ido-active-buy-section .input-group {
    padding-bottom: 6px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.363);
  }

  .ido-active-buy-section .input-group-sm>.form-control {
    padding: 0.05rem 0.5rem;
  }

  .ido-active-buy-section ::placeholder {
    color: #fff !important;
  }

  .form-control:focus {
    background-color: transparent;
  }

  .form-control {
    box-shadow: none !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff !important;
    background-color: transparent;
    border: 0px solid #ced4da;
  }

  .ido-active-buy-max {
    background-color: #f4b844;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    border: none;
    height: 22px;
    margin-top: 2px;
  }

  .ido-active-buy-section .input-group-text {
    font-size: 13px;
    font-weight: 600;
    border: none;
  }

  .ido-active-buy-section .input-group-text img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    position: relative;
    top: -2px;
  }

  .ido-active-buy-section {
    background-color: #15296f;
    padding: 22px 16px;
    border-radius: 15px;
    position: relative;
  }

  .ido-active-text-8 {
    font-size: 14px;
    font-weight: 500;
  }

  .ido-active-text-7 {
    font-size: 18px;
    font-weight: 500;
  }

  .id-active-background-bg {
    background-color: #15296f;
    padding: 12px 16px;
    border-radius: 15px;
  }

  .ido-active-text-6 {
    color: #c2c2c2;
  }

  .ido-active-text-5 {
    font-size: 18px;
  }

  .ido-level-button-1 {
    background-color: #15296f;
    border-radius: 12px;
    padding: 4px 8px;
    font-size: 14px;
  }

  .ido-active-image-3 img {
    width: 60px;
    border-radius: 50%;
  }

  .ido-copy-button {
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    padding: 4px 12px 4px 8px;
    background: rgba(244, 184, 68, 0.1);
    border-radius: 15px;
    border: none;
  }

  .ido-active-text-4 {
    font-size: 16px;
  }

  .ido-active-text-3 {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .active-ido-background {
    background-color: #060621;
    padding: 20px 25px;
    border-radius: 10px;
  }

  .ido-icon-button-1 {
    position: relative;
    top: -2px;
    background-color: #15296f;
    border-radius: 50%;
    padding: 4px;
    font-size: 25px;
    margin-right: 4px;
  }

  .ido-active-text-2 {
    font-size: 14px;
    line-height: 180%;
    letter-spacing: -0.02em;
    color: #c2c2c2;
  }

  .ido-active-button-1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    padding: 6px 18px;
    color: #f4b844;
    background: rgba(244, 184, 68, 0.2);
    border: none;
    border-radius: 8px;
  }

  .ido-active-image-2 img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #373737;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    margin-left: 0;
    padding: 8px;
  }

  .ido-active-text-1 {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .ido-active-image-1 img {
    width: 60px;
    border-radius: 50%;
  }

  .roadmap-image-2 {
    width: 60%;
    float: middle;
  }

  .fade-left-img {
    width: 40px;
    height: 20px;
    position: relative;
    top: -2px;
  }

  .input-group-text {
    box-shadow: none !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-color: transparent;
    border: 1px solid #ced4da;
    border-right: 0px;
  }

  .hero-section-ido-launchpad-tabs-banner .form-control {
    box-shadow: none !important;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-color: transparent;
    border: 1px solid #ced4da;
    border-left: 0px;
  }

  .hero-section-ido-launchpad-tabs-banner .nav-pills .nav-link.active,
  .hero-section-ido-launchpad-tabs-banner .nav-pills .show>.nav-link {
    background: linear-gradient(90deg,
        #1b9ffe 0%,
        rgb(115, 90, 255) 100%) !important;
  }

  .hero-section-ido-launchpad-tabs-banner .nav-link {
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    -webkit-transform: skew(-15deg);
    transform: skew(-15deg);
  }

  .hero-section-ido-launchpad-tabs-banner .nav-link:hover {
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .ido-launchpad-button {
    padding: 10px 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 12px;
    border: none;
    background: linear-gradient(90deg,
        #1b9ffe 0%,
        rgb(115, 90, 255) 100%) !important;
    color: #fff !important;
    overflow: hidden;
    font-size: 14px !important;
    font-weight: 300 !important;
    transition: all 0.3s ease-in-out;
    outline: none !important;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .application-form-ido-section {
    padding: 22px 16px;
    background: #060621;
    backdrop-filter: blur(16.5px);
    -webkit-backdrop-filter: blur(16.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.021);
  }

  .hero-section-ido-launchpad-banner .form-check-label {
    font-size: 13px;
    margin-bottom: 0.5rem;
  }

  .hero-section-ido-launchpad-banner .form-check-input {
    box-shadow: none !important;
  }

  .hero-section-ido-launchpad-banner .form-control:focus {
    background-color: rgba(255, 255, 255, 0);
    border-color: #ced4da;
  }

  .hero-section-ido-launchpad-banner .form-control {
    box-shadow: none !important;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff !important;
    background-color: rgba(255, 255, 255, 0);
    background-clip: padding-box;
    border-bottom: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .footer-section-bottom {
    background-image: url("./images/banner/footer-bg-section.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .footer-social-icons a {
    width: 50px;
    height: 30px;
  }

  .footer-link-section a {
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    margin-right: 16px;
    letter-spacing: 1px;
  }

  .banner-top-fixed-images {
    position: absolute;
    top: 0%;
    right: 0%;
    width: 30%;
    height: 30%;
    z-index: -1;
  }

  .banner-top-fixed-images-particles-1 {
    position: absolute;
    top: 20%;
    left: 10%;
    width: 250px;
    height: 200px;
    z-index: -1;
  }

  .banner-top-fixed-images-particles-2 {
    display: none;
  }

  .banner-top-images {
    width: 85%;
  }

  .contact-text-2 {
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 2px;
  }

  .contact-text-1 {
    font-size: 1.3rem;
    font-weight: 800;
    letter-spacing: 2px;
  }

  .team-text-3 {
    font-size: 20px;
    margin-right: 8px;
  }

  .team-text-2 {
    font-size: 15px;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 2px;
  }

  .team-text-1 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 2px;
  }

  .team-card img {
    width: 150px;
    height: 150px;
  }

  .roadmap-image-1 img {
    display: none;
  }

  .roadmap-text-1 {
    /* background: linear-gradient(90deg, #1b9ffe 0%, rgb(164, 147, 255) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent; */
    font-size: 2rem !important;
    letter-spacing: 0.02em !important;
    letter-spacing: 0.13rem !important;
    font-weight: 400;
  }

  .eight-section-banner .card {
    background-image: url("./images/banner/bg-card.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  /* .fifth-section-banner {
    background-image: url("./images/banner/bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 50px;
    padding-bottom: 50px;
  } */

  .left-days-card-section {
    padding-left: 10px;
    background: linear-gradient(90deg,
        #1b9ffe3b 0%,
        rgba(115, 90, 255, 0.281) 100%) !important;
    width: 50%;
    height: 100%;
    padding: 5px 3px;
    clip-path: polygon(0% 0%, 100% 0, 96% 45%, 91% 100%, 0% 100%);
  }

  .ido-text-13 {
    margin-left: 5%;
    font-size: 24px;
    font-weight: 600;
  }

  .ido-text-12 {
    font-size: 12px;
    font-weight: 600;
  }

  .ido-text-11 {
    font-size: 13px;
    font-weight: 400;
    color: #ffffffd3;
    letter-spacing: 0.5px;
  }

  .ido-text-10 {
    font-size: 0.7rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  .ido-text-9 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  .star-second-image {
    margin-left: 10px;
    position: relative;
    top: -6px;
    width: 25px;
  }

  .two-section-banner {
    background-color: transparent;
    background-image: linear-gradient(180deg, transparent 50%, #06062163 10%);
  }

  .third-section-banner {
    background-color: #06062163;
  }

  .steps-section-image img {
    width: 30px;
    height: 30px;
  }

  .steps-section-image-launch img {
    width: 30px;
    height: 30px;
  }

  .ido-text-8 {
    font-size: 0.8rem;
    font-weight: 500;
  }

  .ido-text-7 {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .ido-text-6 {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 2px;
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
  }

  .ido-text-3 {
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
  }

  .ido-text-4 {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .ido-text-44 {
    margin: auto;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
  }

  .ido-text-5 {
    color: #82b8df;
    font-size: 0.95rem;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .swiper-sale-image-1 img {
    width: 60px;
    height: 60px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .swiper-sale-image-2 img {
    width: 40px;
    height: 40px;
  }

  .box {
    padding: 2vh 5vh;
    position: relative;
    /* height: 400px; */
    background: #060621;
    background-image: url("../src/images/inner/swiper-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .box::before {
    align-items: center;
    content: "";
    position: absolute;
    right: -2px;
    left: -2px;
    top: -2px;
    bottom: -2px;
    background: #fff;
    z-index: -1;
    transform: skew(1deg, 1deg);
    background: linear-gradient(90deg,
        #1b9ffe 0%,
        rgb(115, 90, 255) 100%) !important;
  }

  .get-start-1 {
    padding: 6px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 25px;
    border: none;
    background: linear-gradient(90deg,
        #1b9ffe 0%,
        rgb(115, 90, 255) 100%) !important;
    color: #fff !important;
    overflow: hidden;
    font-size: 12px !important;
    font-weight: 300 !important;
    transition: all 0.3s ease-in-out;
    outline: none !important;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .ido-text-2 {
    font-size: 0.7rem !important;
  }

  .ido-text-1 {
    font-size: 1rem !important;
    color: #1b9ffe;
    font-weight: 600;
  }

  .navbar-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }

  .navbar .nav-link {
    display: inline-block;
    color: #fff;
    text-decoration: none;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .navbar .nav-link::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: #fff;
    transition: width 0.3s;
  }

  .navbar .nav-link:hover::after {
    width: 100%;
  }

  .navbar-toggler {
    background-color: #c0c0c0;
  }

  .navbar-background-color {
    padding: 18px 18px 18px 36px;
    /* background: #060621; */
    /* box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.178); */
    backdrop-filter: blur(1.5px);
    -webkit-backdrop-filter: blur(1.5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.021);
  }

  /* @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        .navbar-background-color {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            background-color: rgba(255, 255, 255, 0.089);
        }
    } */

  .navbar .nav-link {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .navbar .nav-link:hover {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  content: none !important;
}

#piediv tspan {
  color: #fff !important;
  fill: #fff !important;
}

#piediv polyline {
  color: #fff !important;
  fill: #fff !important;
}

#piediv polyline {
  color: #fff !important;
  stroke: #fff !important;
}

/* rect:nth-last-child(){
  display: none !important;
} */

.roadmap-swiper {
  padding-top: 60px !important;
}

.roadmap-swiper .swiper-button-next,
.roadmap-swiper .swiper-button-prev {
  top: 25px !important;
}

.roadmap-swiper .swiper-button-next {
  right: 100px;
}

.roadmap-swiper .swiper-button-prev {
  left: auto !important;
  right: 180px !important;
}

.banner-top-fixed-timer-rool-image-timer {
  width: 75vh;
  height: 75vh;
  position: relative;
  left: 30vw;
}

.network-href-button {
  cursor: pointer;
  text-decoration: underline;
}

.ido-text-total {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
}

.marquee-container {
  width: 100%;
  height: 50px;
  vertical-align: middle;
  background-color: #000;
  font-size: 1.06rem;
  font-weight: 400;
  display: table-cell;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: sans-serif;
}

.marquee-icon-1 {
  margin-bottom: 4px;
  margin-left: 8px;
  font-size: 1.3rem;
}

.marquee-text-1 {
  margin-left: 70px;
}

.overlay::before,
.overlay::after {
  background: none !important;
  content: "";
  height: 100%;
  position: absolute;
  width: 0px;
  z-index: 2;
}

.marquee-container a {
  cursor: pointer;
}

.huwXap {
  color: #fff !important;
  background: transparent !important;

  border-bottom: 0px;
}

.ewhdCu {
  color: #fff !important;
  background: transparent !important;
}

.eoCEVt {
  color: #fff !important;
  background: transparent !important;
}

.eoCEVt:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(238, 238, 238);
  transition-duration: 0.15s;
  transition-property: background-color;
  border: none !important;
  outline: none !important;
}

.eoCEVt:not(:last-of-type) {
  border-bottom: 0px solid rgba(0, 0, 0, 0.12) !important;
}

.cOhUY {
  background: rgba(31, 125, 192, 0.027) !important;
  border: 1px solid #3131913f;
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;

  color: #fff !important;
}

.hXLGuA {
  background: rgba(31, 125, 192, 0.027);
  border: 1px solid #3131913f;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  border-bottom: 1px solid #3131911a;
}

.jWpMhA {
  border-bottom: 1px solid #3131913f !important;
  background: #060621a6 !important;
  /* box-shadow: 0 8px 32px 0 rgb(31 38 135 / 18%); */
  backdrop-filter: blur(16.5px);
  -webkit-backdrop-filter: blur(16.5px);
  color: #fff !important;
}

.cOhUY svg {
  fill: #fff !important;
}

.dqfNny {
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 800;
}

.blogread-icon-1 {
  font-size: 1.5rem;
}

.blog-read-more-section {
  display: grid;
  grid-template-columns: 1.5fr 0.2fr;
  grid-gap: 30px;
  justify-content: center;
}

.blog-read-more-section aside {
  padding-left: 20px;
  padding-right: 20px;
}

.blog-read-more-section aside>ol,
.blog-read-more-section aside>ul {
  position: sticky;
  top: 80px;
}

.blog-read-more-section aside>ol a,
.blog-read-more-section aside>ul a {
  text-decoration: none;
  display: inline-block;
}

.blog-read-more-section aside>ol a:hover,
.blog-read-more-section aside>ul a:hover {
  color: #5f5f5f;
}

.blog-read-more-section aside ul,
.blog-read-more-section aside ol {
  list-style: none;
}

.blog-read-more-section aside ul li>ul {
  padding-left: 10px;
}

.loader-gif {
  width: 6vw;
}

.leadership-level {
  width: 30px;
  height: 30px;
}

.buy-coin-links-logo-size {
  width: 100px;
  height: 100px;
}

.buy-coin-links-icons-css {
  color: #f1ba34 !important;
  font-size: 20px;
}

#buyCoinModal .btn-close {
  background-image: url("../src/images/banner/cross.white.svg") !important;
}

.made-by-decosystem-banner-section img {
  height: auto !important;
}

.made-by-decosystem-banner-section .card:hover {
  border: 1px solid #00e5ff42;
}

.manualconnect-modal-section {
  background: rgb(18, 22, 55, 0.1);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.manualconnect-modal-section .modal-content {
  background: rgb(18, 22, 55, 0.1);
  background-image: url("../src/images/inner/swiper-bg.png") !important;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border: 1px solid #00e5ff21;
  border-radius: 12px;
}

.total-raised-image-styling {
  position: absolute;
  bottom: 0em;
  width: 350px;
  height: 300px;
  left: 0px;
}

/* ========================================Sowmiyaa-27-09===================================== */

.activeyourplan-input-bg-css input {
  background-color: rgb(4, 5, 27) !important;
  color: #ffffff !important;
  border: 1px solid #4b4dc1 !important;
}

.activeyourplan-input-bg-css .form-select {
  background-color: rgb(4, 5, 27) !important;
  color: #ffffff !important;
  border: 1px solid #4b4dc1 !important;
}

.activeyourplan-input-bg-css .form-select {
  box-shadow: none !important;
  outline: none !important;
}

.launch-text-1-activeplan {
  font-size: 0.9rem;
  margin: auto;
}

.sale-body-2-active-plan {
  padding: 50px 50px;
  background: #121637;
  background-image: url("../src/images/inner/swiper-bg.png");
  background-size: 100% 100%;
  border-radius: 4px;
}

.linkcolor{
   text-decoration: underline;
   color: #0d6efd !important;
}